@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    font-family: 'Roboto', sans-serif;
  }
}

